@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh
// styles for themes/mac_base/template_api/products/product_inventory_status_v1/product_inventory_status_v1.mustache

.product-inventory-status {
  &__item,
  &--limited-remaining {
    display: none;
    .product-full & {
      text-align: center;
    }
  }
  &--button {
    background-color: $color--gray--light;
    color: $color--white;
    font-family: $ano-bold_regular-font;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    @if $fonts_update == true {
      @include h6();
      line-height: 50px;
    }
    .product-brief & {
      background-color: $color--white;
      border: 1px solid $color--gray--light;
      color: $color--gray--light;
      font-family: $roboto-mono_regular-font;
      font-size: 12px;
      font-weight: normal;
      height: 36px;
      line-height: 36px;
      @include breakpoint($bp--large-up) {
        background-color: $color--gray--light;
        color: $color--white;
        font-size: 15px;
        height: 45px;
        line-height: 45px;
        padding: 0 30px;
      }
    }
    // notify me flaged with MISC_FLAG
    &.notify-display--soldout-139 {
      display: none;
    }
  }
  &__sold-out-notify {
    display: none;
    background-color: $color--black;
    color: $color--white;
    &:hover {
      color: $color--white;
      text-decoration: none;
    }
    a:focus {
      color: $color--white;
    }
    // notify me flaged with MISC_FLAG
    &.notify-display--soldout-139 {
      display: block;
      .product-inventory-status--button {
        border: 0;
        background-color: $color--black;
        display: inline-block;
        &:hover,
        &:active {
          color: $color--white;
        }
      }
    }
  }
  &--link {
    color: $color--black;
    font-family: $ano-bold_regular-font;
    font-size: 18px;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    .product-brief & {
      color: $color--gray--light;
      font-family: $roboto-mono_regular-font;
      font-size: 12px;
      height: 36px;
      line-height: 36px;
      @include breakpoint($bp--large-up) {
        background: $color--white;
        font-size: 15px;
        height: 45px;
        line-height: 45px;
      }
    }
  }
  &--preorder {
    .product-brief-v2 & {
      @include breakpoint($bp--medium-up) {
        line-height: 1.1;
      }
    }
  }
  &__temp-oos-text,
  &__coming-soon-text {
    .product-brief & {
      display: none;
    }
    .product-brief-v2 & {
      display: block;
      line-height: 1.6;
      margin-top: 0;
      text-transform: lowercase;
      @include breakpoint($bp--medium-up) {
        margin-top: 0;
        line-height: 1.5;
      }
    }
    .notify-status {
      .product-brief-v2 & {
        position: absolute;
        height: 51px;
        line-height: 50px;
        width: 100%;
        background: $color--black;
      }
      .notify_me {
        .product-brief-v2 & {
          color: $color--white;
          font-family: $ano-bold_regular-font;
          border: none;
          font-size: 18px;
          position: absolute;
          left: 0;
          width: 100%;
        }
      }
    }
  }
  &__temp-oos-short-text {
    .product-full & {
      display: none;
    }
    .product-brief & {
      display: none; // temporary hide - preserve for testing post-launch
      background-color: $color--white;
      line-height: 1;
      padding-top: 1em;
    }
  }
  &__item--inactive {
    .product-brief & {
      background-color: $color--white;
      @include breakpoint($bp--large-up) {
        padding: 1em;
      }
    }
  }
}

.product-brief-v2 {
  .product-brief {
    &__inventory-status {
      .product-inventory-status {
        &__list {
          max-width: 49%;
          position: relative;
        }
      }
    }
  }
}
